.icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    svg {
        display: block;
        &.primary {
            fill: #0099cc;
        }
        &.secondary {
            fill: #00af3f;
        }
        &.default {
            fill: #888888;
        }
        &.white {
            fill: white;
        }
    }
}
