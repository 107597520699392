.spinner {
    width: 1em;
    height: 1em;
    border-radius: 50%;

    &__line {
        fill: none;
        opacity: 1;
        stroke-linecap: round;
        transform-origin: 50% 50%;
        transform: rotate3d(0, 0, 1, 0deg);
        animation: 2156ms spinner-arc ease-in-out infinite,
            1829ms spinner-rotate linear infinite;
    }

    &__ring {
        fill: none;
        stroke: #000000;
        stroke-width: 3;
        opacity: 0.3;
    }
}

@keyframes spinner-rotate {
    to {
        transform: rotate3d(0, 0, 1, 360deg);
    }
}
@keyframes spinner-arc {
    from {
        stroke-dasharray: 0 150;
        stroke-dashoffset: 0;
    }
    to {
        stroke-dasharray: 100 150;
        stroke-dashoffset: -140;
    }
}
