@font-face {
    font-family: "FocoRegular";
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.27;
    src: url("../fonts/foco_std_rg-webfont.eot?");
    src: url("../fonts/foco_std_rg-webfont.eot?putcap#iefix") format("embedded-opentype"),
        url("../fonts/foco_std_rg-webfont.ttf?putcap") format("truetype"),
        url("../fonts/foco_std_rg-webfont.woff?putcap") format("woff"),
        url("../fonts/foco_std_rg-webfont.svg?putcap#alimenta") format("svg");
    font-display: swap;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: "FocoRegular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-bg);
    color: var(--body-color);
}

html,
body,
#root {
    box-sizing: border-box;
    margin: 0;
    height: 100%;
    font-size: 14px;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
    font-family: "FocoRegular";
    font-size: 14px !important;
    font-family: "FocoRegular";
}

body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 5px;
}

.container-edit {
    width: 100%;
    max-width: 1280px;
    margin: 3px auto;
}
