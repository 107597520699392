@import "./theme";
@import "./bootstrap-grid";
@import "./mixins/media";
@import "./mixins/box-shadow";
@import "./animation";

.tooltip-content {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 12px;
    position: absolute;
    right: -50px;
    bottom: 47px;
    white-space: pre-line;
    display: none;
    z-index: 5;
    font-size: 13px;
    color: #444444;
    line-height: 15px;
    width: 160px;
    @include materialBoxShadow(2);
    span {
        color: var(--secondary);
    }
}

.tooltip {
    position: relative;
    &:hover .tooltip-content {
        display: block;
    }
    img {
        cursor: pointer;
        display: block;
    }
}
