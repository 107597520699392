.credit-line {
    width: 450px;
    padding: 12px 30px;
    border-radius: 45px;
    border: solid 2px var(--link-color);
    &__credit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--total {
            @extend .credit-line__credit;
            font-size: 16px;
        }
        &--description {
            @extend .credit-line__credit;
            font-size: 14px;
        }
    }
    &__line {
        margin: 7px 0px;
        width: 100%;
        height: 10px;
        background-color: var(--link-color);
        border-radius: 5px;
        &--available {
            @extend .credit-line__line;
            background-color: var(--secondary-color);
        }
        &--unavailable {
            @extend .credit-line__line;
            background-color: var(--alternative-color);
        }
    }
}

.unavailable-color {
    color: var(--alternative-color);
}

.available-color {
    color: var(--secondary-color);
}

.default-color {
    color: var(--body-color-secondary);
}
