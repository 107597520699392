@mixin theme-light {
    --body-bg: #fff;
    --body-bg-secondary: #f8f8f8;
    --body-color: #444444;
    --body-color-secondary: #a9a9a9;
    --link-color: #dee2e6;
    --link-color-hover: #fff;
    --default-color: #fff;
    --primary-color: #00af3f;
    --secondary-color: #0099cc;
    --warning-color: #ff6319;
    --alternative-color: #e93e75;
}

@mixin theme-dark {
    --body-bg: #212529;
    --body-bg-secondary: #3c4043;
    --body-color: #fff;
    --link-color: #dee2e6;
    --link-color-hover: #fff;
    --primary-color: #fff;
    --default-color: #212529;
    --primary-color: #00af3f;
    --secondary-color: #0099cc;
    --warning-color: #ff6319;
    --alternative-color: #e93e75;
}

:root {
    @include theme-light;
}

[data-theme="light"] {
    @include theme-light;
}

// :root {
//     @include theme-light;
// }
// @media (prefers-color-scheme: dark) {
//     :root {
//         @include theme-dark;
//     }
// }
// @media (prefers-color-scheme: light) {
//     :root {
//         @include theme-light;
//     }
// }
// [data-theme="dark"] {
//     @include theme-dark;
// }
// [data-theme="light"] {
//     @include theme-light;
// }
