.navbar {
    height: 50px;
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    position: fixed;
    width: 100%;
    z-index: 4;
    &__public {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
    }
    &__private {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        text-align: center;
        color: white;
    }
}
