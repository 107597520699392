table {
    width: 100%;
    border-collapse: collapse;
    td,
    th {
        padding: 8px;
        border-bottom: 1px solid #ccc;
        text-align: left;
    }

    th {
        color: 000;
        font-weight: bold;
    }
}

.selected-row {
    background-color: rgba($color: #33aed7, $alpha: 0.12);
}
