.loading-overlay {
    background-color: rgba($color: #fff, $alpha: 0.5);
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    &__percentage {
        color: #ffffff;
        font-weight: bold;
        padding: 8px;
    }
    &__container {
        position: relative;
        .loading-overlay__percentage {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    h6 {
        color: #ffffff;
        font-weight: bold;
        padding: 8px;
    }
}
