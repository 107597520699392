.pagination {
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__input {
        width: 60px !important;
        margin-right: 15px;
    }
    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        text-align: center;
    }
    &__item {
        border: none;
        padding: 5px;
    }
    &__button {
        border: 1px solid var(--secondary-color);
        padding: 10px 14px;
        color: var(--secondary-color);
        background-color: var(--default-color);
        border-radius: 50%;
        cursor: pointer;
        &--selected {
            background-color: var(--secondary-color);
            color: var(--default-color);
        }
    }
    // &__input {
    //     padding: 5px 14px;
    //     width: 50px;
    //     height: 40px;
    //     border: 2px solid var(--secondary-color);
    //     //border-radius: 50%;
    //     &:focus {
    //         outline: none;
    //         // border: 2px solid var(--secondary-color);
    //         // border-radius: 50%;
    //     }
    // }
}
