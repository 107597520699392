.generic-message {
    padding: 10px;
    text-align: center;
    width: 400px;
    margin: 0px auto;
    &__title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #444444;
        margin: 15px 0px;
    }

    &__content {
        text-align: center;
        padding: 5px;
    }
    &__footer {
        margin-top: 2em;
        padding: 0.5em;
    }
}
